



























import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Post = {
  id: number;
};

@Component({
  components: {
    Fab: () => import("@/app/modules/whiteboard/components/Fab.vue"),
    PersonalPost: () => import("@/app/modules/whiteboard/components/PersonalPostComponent.vue")
  }
})
export default class PersonalPostsPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("Personal posts (Whiteboard)");
  }

  fab = false;
  // posts: Post[] = [];
  lazyLoaded: number[] = [];

  get isApiFetching() {
    return this.$store.getters["api/isApiFetching"];
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get personalPostsData() {
    return this.$store.getters["posts/personalData"];
  }

  created() {
    if (!this.personalPostsData) this.fetchPosts();
  }

  fetchPosts() {
    this.$store.dispatch("posts/fetchPersonal").then(({ data }) => {
      if (!this.personalPostsData) this.$store.commit("posts/setPersonalData", [...data.data]);
      else this.$store.commit("posts/setPersonalData", [...this.personalPostsData, ...data.data]);
    });
  }
}
